.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.App-header {
  background-color: #1a73e8;
  padding: 15px 20px;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.App-header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}

.user-controls {
  display: flex;
  align-items: center;
}

.welcome-message {
  margin-right: 15px;
  font-size: 16px;
}

.user-menu {
  display: flex;
  align-items: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.logout-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.auth-links {
  display: flex;
}

main {
  flex: 1;
  padding: 40px 20px;
  background-color: #f8f9fa;
}

.home-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
}

/* Hero Section */
.hero-section {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1476514525535-07fb3b4ae5f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80');
  background-size: cover;
  background-position: center;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  margin-bottom: 40px;
  border-radius: 0;
}

.hero-content {
  max-width: 800px;
  padding: 0 20px;
}

.hero-content h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.hero-content p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.cta-container {
  margin: 30px 0;
}

.cta-button {
  display: inline-block;
  background-color: #1a73e8;
  color: white;
  text-decoration: none;
  padding: 14px 28px;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #0d62c9;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* Section Styles (shared between Most Viewed Blogs and Top Influencers) */
.most-viewed-blogs-section,
.top-influencers-section {
  margin-top: 40px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.most-viewed-blogs-section h3,
.top-influencers-section h3 {
  color: #202124;
  font-size: 24px;
  margin-bottom: 25px;
  text-align: left;
}

.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
  text-align: left;
}

.blog-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: white;
  border: 1px solid #e0e0e0;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.blog-thumbnail {
  height: 180px;
  overflow: hidden;
}

.thumbnail-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.blog-card:hover .thumbnail-img {
  transform: scale(1.05);
}

.blog-info {
  padding: 15px;
}

.blog-title {
  margin: 0 0 10px 0;
  font-size: 18px;
  line-height: 1.4;
}

.blog-title a {
  color: #202124;
  text-decoration: none;
  transition: color 0.3s;
}

.blog-title a:hover {
  color: #1a73e8;
}

.influencer-info {
  margin-bottom: 10px;
  font-size: 14px;
}

.by-text {
  color: #5f6368;
}

.influencer-name {
  color: #1a73e8;
  text-decoration: none;
  font-weight: 500;
}

.influencer-name:hover {
  text-decoration: underline;
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  color: #5f6368;
}

.publish-date {
  font-style: italic;
}

.blog-stats {
  display: flex;
  gap: 15px;
}

.views-count, .likes-count {
  display: flex;
  align-items: center;
}

.no-blogs {
  text-align: center;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #5f6368;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #1a73e8;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  text-align: center;
  padding: 20px;
  color: #d93025;
  background-color: #fce8e6;
  border-radius: 8px;
}

/* Top Influencers Section */
.influencers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 25px;
  text-align: left;
}

.influencer-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: white;
  border: 1px solid #e0e0e0;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.influencer-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.influencer-header {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.influencer-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  border: 2px solid #1a73e8;
}

.influencer-avatar-placeholder {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #1a73e8;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  margin-right: 15px;
}

.influencer-name-container {
  flex-grow: 1;
}

.influencer-name {
  margin: 0 0 5px 0;
  font-size: 18px;
  line-height: 1.3;
}

.influencer-name a {
  color: #202124;
  text-decoration: none;
  transition: color 0.3s;
}

.influencer-name a:hover {
  color: #1a73e8;
}

.influencer-username {
  font-size: 14px;
  color: #5f6368;
}

.influencer-bio {
  margin: 0 0 15px 0;
  font-size: 14px;
  line-height: 1.5;
  color: #5f6368;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.influencer-stats {
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  padding: 10px 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-value {
  font-size: 18px;
  font-weight: bold;
  color: #1a73e8;
}

.stat-label {
  font-size: 12px;
  color: #5f6368;
  margin-top: 5px;
}

.influencer-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 15px 0;
}

.website-link, .social-link {
  display: inline-block;
  padding: 6px 12px;
  background-color: #f1f3f4;
  color: #202124;
  text-decoration: none;
  border-radius: 4px;
  font-size: 13px;
  transition: background-color 0.3s;
}

.website-link:hover, .social-link:hover {
  background-color: #e0e0e0;
}

.view-profile-link {
  display: block;
  text-align: center;
  margin-top: auto;
  padding: 10px;
  background-color: #1a73e8;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.view-profile-link:hover {
  background-color: #0d62c9;
}

.no-influencers {
  text-align: center;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #5f6368;
}

footer {
  background-color: #f1f3f4;
  padding: 15px;
  color: #5f6368;
  font-size: 14px;
  border-top: 1px solid #dadce0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    text-align: center;
  }
  
  .App-header h1 {
    margin-bottom: 15px;
  }
  
  .user-controls {
    margin-top: 10px;
  }
  
  .features {
    flex-direction: column;
  }
  
  .feature {
    margin: 10px 0;
  }
  
  .blogs-grid {
    grid-template-columns: 1fr;
  }
}
