.auth-container {
  max-width: 450px;
  margin: 50px auto;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.auth-header {
  text-align: center;
  margin-bottom: 30px;
}

.auth-header h1 {
  color: #1a73e8;
  font-size: 28px;
  margin-bottom: 10px;
}

.auth-header p {
  color: #5f6368;
  font-size: 16px;
}

.auth-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #202124;
}

.form-group input {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.form-group input:focus {
  outline: none;
  border-color: #1a73e8;
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.error-message {
  color: #d93025;
  font-size: 14px;
  margin-top: 5px;
}

.validation-error {
  color: #d93025;
  font-size: 14px;
  margin-top: 5px;
}

.auth-button {
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.auth-button:hover {
  background-color: #0d62c9;
}

.auth-button:disabled {
  background-color: #a9a9a9;
  cursor: not-allowed;
}

.auth-footer {
  margin-top: 25px;
  text-align: center;
  color: #5f6368;
  font-size: 14px;
}

.auth-link {
  color: #1a73e8;
  text-decoration: none;
  font-weight: 500;
}

.auth-link:hover {
  text-decoration: underline;
}

.auth-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.auth-divider::before,
.auth-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #dadce0;
}

.auth-divider span {
  padding: 0 10px;
  color: #5f6368;
  font-size: 14px;
}

/* Animation for error messages */
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% { transform: translateX(-5px); }
  20%, 40%, 60%, 80% { transform: translateX(5px); }
}

.shake {
  animation: shake 0.6s;
}
