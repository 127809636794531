.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-header {
  background-color: #2c3e50;
  color: white;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.user-controls {
  display: flex;
  align-items: center;
}

.welcome-message {
  margin-right: 1rem;
  font-weight: 500;
}

.user-menu, .auth-links {
  display: flex;
  align-items: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin: 0 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.logout-button {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 0.5rem;
}

.logout-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.influencer-profile-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
  flex-grow: 1;
}

.influencer-header {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.influencer-info {
  display: flex;
  align-items: flex-start;
}

.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 2rem;
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.influencer-details {
  flex-grow: 1;
}

.influencer-details h2 {
  margin: 0 0 0.5rem 0;
  font-size: 1.8rem;
  color: #2c3e50;
}

.username {
  color: #6c757d;
  margin: 0 0 1rem 0;
  font-size: 1.1rem;
}

.bio {
  margin: 1rem 0;
  line-height: 1.6;
  color: #495057;
}

.social-links {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.website-link, .social-link {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: #e9ecef;
  color: #495057;
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: background-color 0.3s;
}

.website-link:hover, .social-link:hover {
  background-color: #dee2e6;
}

.social-media-links {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.blogs-section {
  margin-top: 2rem;
}

.blogs-section h3 {
  margin-bottom: 1.5rem;
  color: #2c3e50;
  font-size: 1.5rem;
}

.blogs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.blog-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: white;
}

.blog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.blog-thumbnail {
  height: 180px;
  overflow: hidden;
}

.thumbnail-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.blog-card:hover .thumbnail-img {
  transform: scale(1.05);
}

.blog-info {
  padding: 1.5rem;
}

.blog-title {
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
  line-height: 1.4;
}

.blog-title a {
  color: #2c3e50;
  text-decoration: none;
  transition: color 0.3s;
}

.blog-title a:hover {
  color: #3498db;
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  color: #6c757d;
}

.publish-date {
  font-style: italic;
}

.blog-stats {
  display: flex;
  gap: 1rem;
}

.views-count, .likes-count {
  display: flex;
  align-items: center;
}

.no-blogs {
  text-align: center;
  padding: 3rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  color: #6c757d;
}

.loading-container, .error-container, .not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  text-align: center;
  padding: 2rem;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.back-link {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #3498db;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.back-link:hover {
  background-color: #2980b9;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .influencer-info {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .profile-picture {
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
  
  .social-links {
    justify-content: center;
  }
  
  .blogs-grid {
    grid-template-columns: 1fr;
  }
}
