.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.page-header {
  background-color: #1a73e8;
  padding: 15px 20px;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.page-header .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.page-header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}

.page-header .user-controls {
  display: flex;
  align-items: center;
}

.page-header .welcome-message {
  margin-right: 15px;
  font-size: 16px;
}

.page-header .user-menu {
  display: flex;
  align-items: center;
}

.page-header .nav-link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.page-header .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.page-header .logout-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.page-header .logout-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.youtube-link-form-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  flex: 1;
}

.form-section {
  margin-bottom: 30px;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-section h2 {
  margin-top: 0;
  color: #333;
  font-size: 24px;
}

.input-group {
  display: flex;
  gap: 10px;
}

.youtube-input {
  flex: 1;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.submit-button {
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #3367d6;
}

.submit-button:disabled {
  background-color: #a9a9a9;
  cursor: not-allowed;
}

.error-message {
  color: #d93025;
  margin-top: 10px;
  font-size: 14px;
}

.loading-container {
  text-align: center;
  margin: 40px 0;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #4285f4;
  width: 40px;
  height: 40px;
  margin: 0 auto 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-note {
  color: #666;
  font-size: 14px;
  font-style: italic;
}

.blog-content-container {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.blog-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 15px;
}

.blog-actions h3 {
  margin: 0;
  color: #333;
  font-size: 22px;
}

.save-button {
  background-color: #34a853;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.save-button:hover {
  background-color: #2e8b57;
}

.save-button:disabled {
  background-color: #a9a9a9;
  cursor: not-allowed;
}

.blog-saved-message {
  display: flex;
  align-items: center;
  gap: 15px;
}

.blog-saved-message span {
  color: #34a853;
  font-weight: 500;
}

.view-blog-link {
  background-color: #1a73e8;
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.view-blog-link:hover {
  background-color: #0d62c9;
}

.blog-content-container h3 {
  margin-top: 0;
  color: #333;
  font-size: 22px;
}

.video-thumbnail-container {
  margin-bottom: 30px;
  text-align: center;
}

.video-thumbnail {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
}

.video-source {
  margin-top: 10px;
  font-size: 14px;
}

.video-link {
  display: inline-block;
  background-color: #ff0000;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.3s;
}

.video-link:hover {
  background-color: #cc0000;
  text-decoration: none;
}

.blog-content {
  line-height: 1.6;
  color: #333;
  font-size: 16px;
}

/* Blog content styling */
.blog-content h1 {
  font-size: 28px;
  color: #1a73e8;
  margin-top: 0;
  margin-bottom: 20px;
}

.blog-content h2 {
  font-size: 24px;
  color: #202124;
  margin-top: 30px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  padding-bottom: 8px;
}

.blog-content h3 {
  font-size: 20px;
  color: #202124;
  margin-top: 25px;
  margin-bottom: 10px;
}

.blog-content p {
  margin-bottom: 16px;
}

.blog-content ul, .blog-content ol {
  margin-bottom: 20px;
  padding-left: 25px;
}

.blog-content li {
  margin-bottom: 8px;
}

.blog-content strong, .blog-content b {
  font-weight: 600;
}

.blog-content em, .blog-content i {
  font-style: italic;
}

.blog-content img {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
  border-radius: 4px;
}

.blog-content a {
  color: #1a73e8;
  text-decoration: none;
}

.blog-content a:hover {
  text-decoration: underline;
}

.blog-content blockquote {
  border-left: 4px solid #1a73e8;
  padding-left: 15px;
  margin-left: 0;
  color: #555;
  font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .input-group {
    flex-direction: column;
  }
  
  .submit-button {
    margin-top: 10px;
  }
  
  .blog-actions {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .blog-saved-message {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
