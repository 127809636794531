.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}

.page-header {
  background-color: #1a73e8;
  padding: 15px 20px;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.page-header .header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.page-header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
}

.page-header .user-controls {
  display: flex;
  align-items: center;
}

.page-header .welcome-message {
  margin-right: 15px;
  font-size: 16px;
}

.page-header .user-menu {
  display: flex;
  align-items: center;
}

.page-header .nav-link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.page-header .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.info-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  background-color: #f8f9fa;
}

.info-card {
  max-width: 700px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
}

.info-icon {
  font-size: 48px;
  margin-bottom: 20px;
}

.info-card h2 {
  color: #1a73e8;
  font-size: 28px;
  margin-bottom: 20px;
}

.info-card p {
  color: #5f6368;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 30px;
}

.benefits {
  text-align: left;
  margin-bottom: 30px;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.benefits h3 {
  color: #202124;
  font-size: 20px;
  margin-bottom: 15px;
}

.benefits ul {
  padding-left: 20px;
}

.benefits li {
  color: #5f6368;
  margin-bottom: 10px;
  font-size: 16px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.primary-button {
  background-color: #1a73e8;
  color: white;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s;
}

.primary-button:hover {
  background-color: #0d62c9;
}

.secondary-button {
  background-color: #f1f3f4;
  color: #5f6368;
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s;
}

.secondary-button:hover {
  background-color: #e8eaed;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .info-card {
    padding: 30px 20px;
  }
  
  .cta-buttons {
    flex-direction: column;
    gap: 10px;
  }
  
  .page-header .header-content {
    flex-direction: column;
  }
  
  .page-header h1 {
    margin-bottom: 10px;
  }
  
  .page-header .user-controls {
    margin-top: 10px;
  }
}
